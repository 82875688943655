@import "assets/styles/colors";
@import "assets/styles/mixins";

.detail-tab {
  padding: 0 50px;
  margin: 110px 0;
  width: 1200px;

  @include respond(mobile) {
    padding: 0;
    margin: 80px 16px;
  }

  &__btns {
    position: relative;

    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__btn {
    font-size: 1.95rem;
    font-weight: bold;
    margin: 0 10px;

    transition: all 0.25s ease-in-out;

    cursor: pointer;
    user-select: none;
  }

  &__btn.active {
    color: $primary-white-color;
  }

  &__btn-background {
    position: absolute;
    top: 0;
    content: "";

    height: 100%;
    border-radius: 10px;
    z-index: -1;

    transition: all 0.35s ease-in-out;

    background-color: $primary-red-color;
  }

  &__content-description {
    font-size: 1.4rem;
    line-height: 1.7;

    @include respond(phone) {
      text-align: center;
    }
  }

  &__content-table {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 7), 1fr));
    margin-top: 40px;

    @include respond(phone) {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }

  &__content-table:before {
    position: absolute;
    content: "";
    top: 0;
    left: 14.5%;

    height: 100%;
    width: 0.5px;

    background-color: rgba(0, 0, 0, 0.15);

    @include respond(phone) {
      left: 50%;

      transform: translateX(-50%);
    }
  }

  &__content-table:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 27%;

    width: 100%;
    height: 0.5px;

    background-color: rgba(0, 0, 0, 0.15);

    @include respond(phone) {
      display: none;
    }
  }

  &__content-col {
    display: flex;
    flex-direction: column;
    text-align: center;

    @include respond(tablet) {
      justify-content: space-evenly;
    }

    @include respond(phone) {
      flex-direction: row;
      padding-top: 35px;
    }
  }

  &__content-col:not(:first-child) {
    @include respond(phone) {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
    }
  }

  &__content-col:first-child {
    padding-top: 10px;

    @include respond(phone) {
      padding-top: 30px;
    }
  }

  &__content-col:first-child :is(&__content-col-title, &__content-ingredients) {
    font-size: 1.8rem;
    font-weight: bold;
  }

  &__content-col-wrapper {
    text-align: center;
    margin-bottom: 50px;

    @include respond(phone) {
      width: 50%;
      margin-bottom: 40px;
    }
  }

  &__content-col-title {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 3px;
    text-transform: capitalize;

    @include respond(mobile) {
      font-size: 2.5rem;
    }
  }

  &__content-col-description {
    color: $primary-black-color;
    font-size: 1.4rem;
    margin-top: 12px;
  }

  &__content-ingredients {
    font-size: 1.4rem;
    margin-top: 5px;

    @include respond(phone) {
      width: 50%;
    }
  }

  &__content-col:not(:first-child) &__content-ingredients {
    @include respond(phone) {
      margin-top: 15px;
    }
  }
}