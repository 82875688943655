@import "assets/styles/colors";
@import "assets/styles/mixins";

.detail-content {
  @include respond(mobile) {
    padding-left: 24px;
  }
  &__title {
    font-size: 2.8rem;
    text-transform: capitalize;
    margin-bottom: 15px;
    height: 3.5rem;

    @include respond(mobile) {
      font-size: 2.5rem;
    }
  }

  &__rate {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__stars {
    margin-right: 8px;
  }

  &__stars svg {
    font-size: 1.8rem;

    fill: $primary-yellow-color;
  }

  &__reviews span {
    font-size: 1.4rem;
  }

  &__form-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 7px;

    @include respond(phone) {
      font-size: 1.6rem;
    }
  }

  &__price {
    color: $primary-red-color;
    font-size: 2.5rem;
    margin-bottom: 20px;

    @include respond(mobile) {
      font-size: 2rem;
    }
  }

  &__tags {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @include respond(phone) {
      flex-wrap: wrap;
      line-height: 1.7;
    }
  }

  &__tag {
    margin-right: 20px;
    font-size: 1.4rem;
  }

  &__tag-label {
    margin-right: 5px;

    color: $primary-grey-color;
  }

  &__tag-detail.category {
    text-transform: capitalize;
  }

  &__description {
    font-size: 1.4rem;
    margin-bottom: 20px;
    line-height: 1.7;
  }

  &__form {
    margin: 20px 0 10px 0;
  }

  &__option-title {
    font-size: 1.6rem;
    font-weight: bold;
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px !important;
    margin-bottom: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    @include respond(phone) {
      flex-wrap: wrap;
      padding: 15px 0;
    }
  }

  &__btn-handle {
    display: flex;
    align-items: center;
    min-width: 12rem;

    @include respond(phone) {
      margin-right: 100%;
    }
  }

  &__btn-qnt {
    font-size: 1.4rem;
    min-width: 3.5rem;
    text-align: center;
  }

  & :is(&__btn-dec, &__btn-inc) {
    font-size: 1.8rem;
  }

  &__add {
    flex: 1;
    margin: 25px 18px 25px 15px;

    @include respond(phone) {
      margin: 15px 0 0;
    }
  }

  .primary-btn {
    width: 100%;
  }

  & &__btn-like {
    font-size: 1.9rem;

    @include respond(phone) {
      margin: 15px 0 0 20px;
    }
  }

  &__btn-like svg {
    font-size: 1.6rem;

    fill: rgba(0, 0, 0, 0.55);
  }

  .btn-circle {
    min-width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;

    background-color: rgba(0, 0, 0, 0.05);
  }

  .btn-circle svg {
    transition: all 0.25s ease-out;
  }

  .btn-circle:hover svg {
    fill: $primary-red-color;
  }

  .btn-circle:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &__commit {
    font-size: 1.4rem;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    padding: 4px 7px;
    border-left: 3.8px solid $primary-red-color;
  }

  &__commit svg {
    font-size: 2.2rem;
    margin-right: 8px;

    fill: $primary-red-color;
  }
}