.banner__review {
    position: relative;
    width: 100%;
    height: 320px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 3;
    }
}