@import "assets/styles/colors";
@import "assets/styles/mixins";

body {
  margin: 0;
  padding: 0px !important;
  overflow: unset !important;
  font-family:Roboto, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  line-height: 1.4rem;
}

.App {
  overflow: hidden;
}

a {
  text-decoration: none;
}

p, h3, h4, h5 {
  margin: 0 !important;
}

::-webkit-scrollbar {
  width: 1.2rem;
  background-color: $scrollbar-background-color;
}

::-webkit-scrollbar-track {
  margin: 3px 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 2px solid transparent;

  background-clip: content-box;
  background-color: $scrollbar-color;
  background-clip: content-box;
 
}


.css-1kxwfp {
  display: 'none !important'
}
.react-pdf__Document {
  display: inline-block;
}

.react-pdf__Page {
  width: 550px;
  height: 733px;
}