@import "assets/styles/colors";
@import "assets/styles/mixins";

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 120px 0;

  &__caption {
    font-size: 12.6rem;
    font-weight: bold;

    @include respond(tablet) {
      font-size: 10.6rem;
    }

    @include respond(phone) {
      font-size: 8.6rem;
    }
  }

  &__img {
    max-width: 50rem;

    @include respond(tablet) {
      max-width: 40rem;
    }

    @include respond(phone) {
      max-width: 30rem;
      padding: 0 20px;
    }
  }

  &__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}