@import "assets/styles/colors";
@import "assets/styles/mixins";

.home-category {
  position: relative;

  text-align: center;
  margin: 100px 0;

  @include respond(mobile) {
    margin: 40px 0 50px;
  }

  &__container {
    border-radius: 10px;
    padding-top: 50px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.08);

    background-color: $primary-white-color;
  }

  &__cards {
    position: relative;

    display: flex;
    padding: 40px 0px 70px;

    @include respond(mobile) {
      padding: 30px 0px 60px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
  }

  &__card-img-wrapper {
    width: 12rem;
    height: 12rem;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.05);

    background-color: $primary-white-color;

    transition: all 0.3s;

    cursor: default;
  }

  @media (hover: hover) {
    &__card:hover &__card-img-wrapper {
      box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
    }
  }

  &__card-img {
    width: 6rem;
    height: 100%;

    fill: $primary-red-color;
  }

  &__card-description {
    margin-top: 20px;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;

    transition: all 0.3s;

    cursor: pointer;
  }

  @media (hover: hover) {
    &__card:hover &__card-description {
      color: $primary-red-color;
    }
  }

  .prev-btn,
  .next-btn {
    position: absolute;
    top: 34%;
    z-index: 1;

    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.08);
    min-width: 3.5rem;
    min-height: 3.5rem;

    &:hover {
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

      background-color: $primary-red-color;
    }

    svg {
      fill: rgba($primary-black-color, 0.7);
    }

    &:hover svg {
      fill: $primary-white-color;
    }

    @include respond(mobile) {
      display: none;
    }
  }

  .prev-btn {
    left: 7px;
  }

  .next-btn {
    right: 7px;
  }

  .swiper-container {
    position: static;

    width: 90%;
  }
}