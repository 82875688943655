.swiper-button {
    &-prev, &-next {
        display: none;
    }
}

.swiper-pagination {
    &-horizontal {
     bottom: 5.6rem !important;
    }
    &-bullet {
        position: relative;
        width: 1.8rem;
        height: 1.8rem;
        margin: 0 1rem !important;
        &-active {
            background-color: #fbb403;
        }
        &::before {
          content: '';
          position: absolute;
          width: 2rem;
          height: 2rem;
          border: 0.2rem solid #fbb403;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%)
        }
    }
}

.slider__background {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 80rem;
}

.slider__content {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 24%;
    z-index: 10;
    padding: 0 24px;
    &-title {
        font-size: 1.8rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        animation: slideWordTitle 0.8s ease;
    }
    &-desc {
        font-size: 7.2rem;
        font-weight: 600;
        line-height: 1.2;
        margin: 5px 0 15px;
        color: #fff;
        animation: slideWordDesc 1.4s ease;
        &-sub {
            font-size: 5.2rem;
            font-weight: 600;
            line-height: 1.2;
            color: #fff;
            margin: -20px 0 10px 0;
            animation: slideWordDesc 1.4s ease;
        }
    }
}

@keyframes slideWordTitle {
    from{
      transform: translateX(-2rem);
    }

    to {
      transform: translateX(0);
      
    }
}

@keyframes slideWordDesc {
    from{
      opacity: 0;
      transform: translateX(4rem);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
}

@keyframes slideWordButton {
    from{
      opacity: 0;
      transform: translateY(3rem);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
}