@import "assets/styles/colors";
@import "assets/styles/mixins";


.shop-filters {
  margin: 18px 0 0 16px;
  &__list {
    padding-left: 0;
  }

  @include respond(mobile) {
    display: none;
  }

  &__title {
    font-size: 2rem;
    padding: 10px 0 10px 8px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 8px;
    border-radius: 6px;

    transition: all 0.1s ease-out;
  }

  &__item.active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &__item:hover {
    background-color: rgba(0, 0, 0, 0.08);

    cursor: pointer;
  }

  &__item img {
    height: 2.2rem;
    width: 2.2rem;
  }

  &__item-name {
    font-size: 1.4rem;
    text-transform: capitalize;
    margin-left: 10px;
  }

  &__form {
    padding: 0 12px;
  }

  &__label {
    position: relative;

    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    margin: 3px 0;

    cursor: pointer;
  }

  &__input {
    display: none;
    margin-right: 5px;
  }

  .checkmark {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;

    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 3px solid $primary-grey-color;
    transform: translateY(-50%);

    transition: all 0.2s ease-out;
  }

  &__input:checked ~ .checkmark {
    border-color: $primary-red-color;
  }

  .checkmark:after {
    position: absolute;
    content: "";
    top: 1px;
    left: 1px;

    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;

    background-color: transparent;

    transition: all 0.1s ease-out;
  }

  &__input:checked ~ .checkmark:after {
    background-color: $primary-red-color;
  }

  &__stars {
    margin: 5px 0;
    padding: 3px 0 3px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__stars svg {
    font-size: 2rem;
    margin-right: 2px;

    fill: $primary-yellow-color;
  }

  &__stars span {
    font-size: 1.4rem;
    margin-left: 4px;
    font-weight: 500;

    transition: all 0.12s ease-out;
  }

  &__stars:hover span {
    color: $primary-red-color;
  }
}

//custom checkbox content
.MuiFormControlLabel-label {
  flex-shrink: 0 !important;
  font-size: 1.4rem !important;
  text-transform: capitalize !important;
}
